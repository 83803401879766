import React from 'react';

export function Section({ bg, color, id, className = '', children }) {
	let classes = bg + ' ' + color + ' py-16';
	if (className) {
		classes += ` ${className}`;
	}
	return (
		<section className={classes} id={id || ''}>
			{children}
		</section>
	)
}