import React from 'react';

export function Head({ title = 'Frameland: A Binary Tale', description = '', noindex = false, children }) {
	return (
		<>
			{noindex && <meta name="robots" content="noindex"></meta>}
			<title>{title}</title>
			<meta name="description" content={description} />

			<meta property="og:description" content={description} />
			<meta property="og:title" content="Frameland – A short RPG with a big heart." />
			<meta property="og:type" content="website" />
			<meta property="og:image" content="/og_image.png" />

			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			<link rel="manifest" href="/site.webmanifest" />

			{children && children}
		</>
	)
}
