import React from 'react';

export function Container({ children }) {
	return (
		<div className="max-w-[64rem] mx-auto">
			{children}
		</div>
	)
}

export function ContentContainer({ children, className = '' }) {
	return (
		<div className={`max-w-2xl mx-auto p-6 pt-10 bg-fl-turkis-light/10 ${className}`} >
			{children}
		</ div>
	)
}
