import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import React from "react"
import { STEAM_URL } from "../utils";

export function Menubar() {
	const { languages, changeLanguage, language } = useI18next();

	return (
		<nav className="flex items-center overflow-hidden flex-col">
			<div className="flex absolute top-14 right-8 bg-fl-highlight-light rounded z-10 small:top-4 small:right-4">
				<img src="/assets/icon_lang.png" className="block h-6 w-6 mt-3 ml-3 opacity-80 -mr-2" alt="" />
				<ul className="flex items-center">
					{languages.filter(lng => language !== lng).map((lng) => (
						<li key={lng} className="px-2 py-3 -mt-0.5">
							<a
								className="px-2 py-3 text-fl-white hover:text-fl-turkis-light uppercase tracking-wider text-sm"
								href="#"
								onClick={(e) => {
									e.preventDefault();
									changeLanguage(lng);
								}}>
								{lng}
							</a>
						</li>
					))}
				</ul>
			</div>
			<MenuBg>
				<ul className="flex flex-row items-center justify-center text-fl-white">
					<Item text="Frameland" url="/" />
					{STEAM_URL && <Item text="Steam" url={STEAM_URL} isExternal={true} />}
					<Item text="Presskit" url="/presskit/" />
				</ul>
			</MenuBg>
		</nav>
	)
}

function MenuBg({ children }) {
	return (
		<div className="w-full bg-fl-keyart text-center opacity-100">
			<span className="px-2 py-4 inline-block text-left">
				{children}
			</span>
		</div>
	)
}

function Item({ text, url, isExternal }) {
	const linkClasses = "px-2 py-2 text-gray-300 hover:text-fl-turkis-light uppercase tracking-wider text-sm";
	return (
		<li className="px-0 py-2 first-of-type:pl-2 last-of-type:pr-2" key={url}>
			{isExternal && (
				<a
					href={url}
					target="_blank"
					rel="noreferrer"
					className={linkClasses}
				>
					{text}
				</a>
			)}
			{!isExternal && (
				<Link
					to={url}
					className={linkClasses}
					activeClassName="menu-active"
				>
					{text}
				</Link>
			)}
		</li>
	)
}
