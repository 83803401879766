import React from 'react';
import { CONTACT_EMAIL } from '../utils';
import { Trans } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';

export function Footer() {
	return (
		<footer className="bg-gradient-to-b from-fl-turkis-dark to-fl-black pt-32 relative mt-auto">
			<div className="absolute w-full opacity-50 h-16 top-1 overflow-hidden">
				<div className="stars absolute left-32 top-2"></div>
				<div className="stars absolute left-0 top-0"></div>
			</div>
			<p className="mx-auto p-6 max-w-sm pb-8 text-gray-300 text-center">
				<Trans i18nKey="footerQuestion">
					Got feedback or questions?
				</Trans>
				<a href={`mailto:${CONTACT_EMAIL}`} className="block underline">
					<Trans i18nKey="footerContact">
						Contact us
					</Trans>
				</a>
			</p>
			<div className="mountain opacity-50"></div>
			<p className="text-center text-gray-300 text-xs px-2 py-3 absolute bottom-0 left-0 right-0">
				<Link to={"/imprint/"}>
					<Trans i18nKey="imprint">
						Imprint
					</Trans>
				</Link>
				<span className="mx-1">|</span>
				<Link to={"/privacy/"}>
					<Trans i18nKey="privacy">
						Privacy
					</Trans>
				</Link>
				<span className="text-gray-300/50 block">
					© {(new Date()).getFullYear()} Frameland Games, all rights reserved.
				</span>
				<span className="text-gray-300/50 block">
					<a href="https://www.flaticon.com/free-icons/language" title="language icons" target="_blank" rel="noreferrer">
						Icon by Freepik - Flaticon, Md Tanvirul Haque
					</a>
				</span>
			</p>
		</footer >
	)
}

