import React from "react"

export function Main({ children }) {
	const style = {
		maxWidth: '100%',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	}
	return <div className="min-h-full bg-fl-turkis-dark overflow-x-hidden" style={style}>{children}</div>
}
