import React from "react"
import { ContentContainer } from "../components/Container";
import { Footer } from "../components/Footer"
import { Main } from "../components/Main";
import { Menubar } from "../components/Menu"
import { Section } from "../components/Section";
import { Head as DefaultHead } from "../head"
import { graphql } from 'gatsby';

function Imprint() {
	return (
		<Main>
			<Menubar />
			<Section bg='bg-fl-turkis-dark' color='text-fl-text'>
				<ContentContainer className="max-w-lg">
					<h1 className="text-3xl font-serif text-center text-fl-turkis-light">
						Imprint / Legal
					</h1>
					<p className="text-md px-2 mt-4">
						Frameland Games e.U., FN 475737 p
						<br />
						Markus Langthaler
						<br />
						Mexikoplatz 15/14, 1020 Wien
						<br />
						contact@frameland.net
						<br />
						IT-Dienstleistungen (Software Entwicklung - Videospiele)
						<br />
						Mitglied der Wirtschaftskammer Wien
					</p>
				</ContentContainer>
			</Section>
			<Footer />
		</Main>
	)
}

export function Head() {
	return (
		<DefaultHead
			title="Frameland: Imprint"
			description=""
			noindex
		/>
	)
}

export default Imprint;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
